import React from 'react'
import CloseIcon from "../../assets/icons/close-icon.png";
import GreenTick from "../../assets/icons/green-tick.png";
import moment from "moment";
import { aadhaarTandC } from '../../constants';

function AadharData({ reportData, documentsObject, imageZoom, step }) {
  let aadhaarData = reportData.documents[
    documentsObject["Aadhar Card"]
  ]
  let isAadhar = false
  let aadhaar_number = ''

  if (!aadhaarData) {
    aadhaar_number = reportData?.case?.aadhaar_number
    if (aadhaar_number && aadhaar_number.length > 0) {
      isAadhar = true
    }
  }
  if (!aadhaarData && !isAadhar) return null
  if (!aadhaarData?.corrected_ocr.tick_and_cross && !isAadhar) {
    aadhaarData.corrected_ocr.tick_and_cross = aadhaarTandC
  }
  const isMigrated = reportData?.case?.old_db_case_id ? true : false
  if (isAadhar) {
    return (
      <>
        <table
          width="100%"
          style={{ border: "1px solid #b1b1b1" }}
          className="bg-white"
        >
          <tr>
            <td style={{ padding: '15px' }}>
              <h4>{step}. Aadhaar Verification</h4>

              <table
                width="100%"
                className="bg-white"
                style={{ marginTop: "15px" }}
              >
                <tr>
                  <td
                    width="400"
                    style={{ padding: "0px 10px 0px 0px" }}
                  >
                    <table
                      width="51%"
                      className="gray-table-rows bg-f8"
                      style={{ overflow: "wrap" }}
                    >
                      <tr>
                        <td
                          width="42%"
                          style={{ paddingRight: "0px" }}
                        >
                          Aadhaar Number
                        </td>
                        <td
                          width="2%"
                          align="center"
                          style={{ padding: "0px" }}
                        >
                          :
                        </td>
                        <td width="48%">
                          {aadhaar_number}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </>
    )
  }
  return (
    <table
      width="100%"
      className="bg-white"
      style={{ border: "1px solid #b1b1b1" }}
    >
      <tr>
        <td style={{ padding: "0px" }}>
          <table width="100%">
            <tr>
              <td style={{ padding: "15px 15px 15px 15px" }}>
                <h4>{step}. Aadhaar Verification</h4>
                {documentsObject["Aadhar Card"] && (
                  <table
                    width="100%"
                    className="bg-white"
                    style={{ marginTop: "15px" }}
                  >
                    <tr>
                      <td
                        valign="top"
                        width="180"
                        style={{ padding: "0px 0px 0px 0px" }}
                      >
                        <table width="100%" className="bg-f8">
                          <tr>
                            <td
                              align="center"
                              style={{
                                padding: "20px",
                                paddingBottom: "10px",
                              }}
                            >
                              <img
                                src={
                                  aadhaarData.front_upload
                                }
                                crossOrigin="anonymous"
                                onClick={() =>
                                  imageZoom(aadhaarData.front_upload)
                                }
                                alt=""
                                width="245"
                                className="img-fluid rounded"
                                style={{ cursor: "pointer" }}
                              />

                            </td>
                          </tr>
                          {aadhaarData.document_upload_type === "ocr_upload" && (
                            <tr>
                              <td
                                align="center"
                                style={{
                                  padding: "20px",
                                  paddingBottom: "10px",
                                }}
                              >
                                <img
                                  src={
                                    aadhaarData.back_upload
                                  }
                                  crossOrigin="anonymous"
                                  onClick={() =>
                                    imageZoom(aadhaarData.back_upload)
                                  }
                                  alt=""
                                  width="245"
                                  className="img-fluid rounded"
                                  style={{ cursor: "pointer" }}
                                />

                              </td>
                            </tr>
                          )}
                          <tr>
                            <td
                              align="center"
                              style={{
                                fontSize: "9px",
                                fontStyle: "italic",
                                padding: "0px 20px 33px 20px",
                              }}
                            >
                              Image file as per aadhaar portal
                            </td>
                          </tr>
                        </table>
                      </td>
                      {aadhaarData.document_upload_type === "digilocker_upload" ? (
                        <td
                          valign="top"
                          style={{ padding: "0px 0px 0px 10px" }}
                        >
                          <table
                            className="green-table-rows bg-f8"
                            width="560"
                          >
                            <tr>
                              <td
                                width="40%"
                                style={{
                                  padding: "5px 20px !important",
                                }}
                              >
                                Aadhaar verification status
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              {aadhaarData.corrected_ocr.tick_and_cross && aadhaarData.corrected_ocr.tick_and_cross[0] && (
                                <td width="43%">
                                  Aadhaar{" "}
                                  {aadhaarData.corrected_ocr.tick_and_cross[0]
                                    .isTick
                                    ? "successfully"
                                    : "not"}{" "}
                                  verified by digi-locker
                                </td>
                              )}
                              <td
                                align="right"
                                width="15%"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "0px",
                                }}
                              >
                                {aadhaarData.corrected_ocr.tick_and_cross[0] && (
                                  <img
                                    src={
                                      aadhaarData.corrected_ocr.tick_and_cross[0]
                                        .isTick
                                        ? GreenTick
                                        : CloseIcon
                                    }
                                    width="20"
                                    alt=""
                                  />
                                )}
                              </td>
                            </tr>
                          </table>
                          <table
                            className="gray-table-rows bg-f8"
                            width="560"
                            style={{ marginTop: "10px" }}
                          >
                            <tr>
                              <td
                                width="40%"
                                style={{ paddingRight: "0px" }}
                              >
                                Aadhaar Upload Date-Time
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="53%">
                                {isMigrated ? moment(
                                  reportData.session.ended_at
                                ).format("DD MMM YYYY LT") : moment(
                                  aadhaarData.created_at
                                ).format("DD MMM YYYY LT")}
                              </td>

                            </tr>
                          </table>
                          {Object.keys(
                            aadhaarData.corrected_ocr.digilocker_data
                          ).length > 0 && (
                              <>
                                <table
                                  className="gray-table-rows bg-f8"
                                  width="560"
                                  style={{ marginTop: "10px" }}
                                >
                                  <tr>
                                    <td
                                      width="40%"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Name
                                    </td>
                                    <td
                                      width="2%"
                                      align="center"
                                      style={{ padding: "0px" }}
                                    >
                                      :
                                    </td>
                                    <td width="53%">
                                      {
                                        aadhaarData.corrected_ocr.digilocker_data
                                          .name
                                      }
                                    </td>
                                  </tr>
                                </table>
                                <table
                                  className="gray-table-rows bg-f8"
                                  width="560"
                                  style={{ marginTop: "10px" }}
                                >
                                  <tr>
                                    <td
                                      width="40%"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Gender
                                    </td>
                                    <td
                                      width="2%"
                                      align="center"
                                      style={{ padding: "0px" }}
                                    >
                                      :
                                    </td>
                                    <td width="53%">
                                      {
                                        aadhaarData.corrected_ocr.digilocker_data
                                          .gender
                                      }
                                    </td>
                                  </tr>
                                </table>
                                <table
                                  className="gray-table-rows bg-f8"
                                  width="560"
                                  style={{ marginTop: "10px" }}
                                >
                                  <tr>
                                    <td
                                      width="40%"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      DOB
                                    </td>
                                    <td
                                      width="2%"
                                      align="center"
                                      style={{ padding: "0px" }}
                                    >
                                      :
                                    </td>
                                    <td width="53%">
                                      {aadhaarData.corrected_ocr.digilocker_data.dob
                                        ? aadhaarData.corrected_ocr.digilocker_data
                                          .dob
                                        : aadhaarData.corrected_ocr.digilocker_data
                                          .yob}
                                    </td>
                                  </tr>
                                </table>
                                <table
                                  className="gray-table-rows bg-f8"
                                  width="560"
                                  style={{ marginTop: "10px" }}
                                >
                                  <tr>
                                    <td
                                      width="40%"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Address
                                    </td>
                                    <td
                                      width="2%"
                                      align="center"
                                      style={{ padding: "0px" }}
                                    >
                                      :
                                    </td>
                                    <td width="53%">
                                      {
                                        aadhaarData.corrected_ocr.digilocker_data
                                          .address
                                      }
                                    </td>
                                  </tr>
                                </table>
                              </>
                            )}
                        </td>
                      ) : (
                        <td
                          valign="top"
                          style={{ padding: "0px 0px 0px 10px" }}
                        >
                          <table
                            className="gray-table-rows bg-f8"
                            width="560"
                          >
                            <tr>
                              <td
                                width="40%"
                                style={{
                                  padding: "5px 20px !important",
                                }}
                              >
                                Name as on Aadhaar Card
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="43%">
                                {
                                  aadhaarData?.corrected_ocr?.name
                                }
                              </td>
                              <td
                                align="right"
                                width="15%"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "0px",
                                }}
                              >
                                {aadhaarData?.corrected_ocr?.tick_and_cross && (
                                  <img
                                    src={
                                      aadhaarData?.corrected_ocr?.tick_and_cross[0]
                                        .isTick
                                        ? GreenTick
                                        : CloseIcon
                                    }
                                    width="20"
                                    alt=""
                                  />
                                )}
                              </td>
                            </tr>
                          </table>
                          <table
                            className="gray-table-rows bg-f8"
                            width="560"
                            style={{ marginTop: "10px" }}
                          >
                            <tr>
                              <td
                                width="40%"
                                style={{ paddingRight: "0px" }}
                              >
                                Address fetched as on Aadhaar Card
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="53%">
                                {
                                  aadhaarData.corrected_ocr.address
                                }
                              </td>
                              <td
                                align="right"
                                width="5%"
                                style={{
                                  paddingRight: "10px",
                                  paddingLeft: "0px",
                                }}
                              >
                                {aadhaarData?.corrected_ocr?.tick_and_cross && aadhaarData?.corrected_ocr?.tick_and_cross[2] && (
                                  <img
                                    src={
                                      aadhaarData.corrected_ocr.tick_and_cross[2]
                                        .isTick
                                        ? GreenTick
                                        : CloseIcon
                                    }
                                    width="20"
                                    alt=""
                                  />
                                )}
                              </td>
                            </tr>
                          </table>
                          <table
                            width="100%"
                            style={{ marginTop: "8px" }}
                          >
                            <tr>
                              <td
                                width="260"
                                style={{ padding: "0px 10px 0px 0px" }}
                              >
                                <table
                                  className="gray-table-rows bg-f8"
                                  width="100%"
                                >
                                  <tr>
                                    <td
                                      width="38%"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Date of birth
                                    </td>
                                    <td
                                      width="2%"
                                      align="center"
                                      style={{
                                        paddingLeft: "10px",
                                        padding: "10px",
                                      }}
                                    >
                                      :
                                    </td>
                                    <td width="55%">
                                      {" "}
                                      {
                                        aadhaarData.corrected_ocr.dob
                                      }
                                    </td>
                                    <td
                                      align="right"
                                      width="5%"
                                      style={{
                                        paddingRight: "10px",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      {aadhaarData?.corrected_ocr
                                        ?.tick_and_cross && aadhaarData?.corrected_ocr
                                          ?.tick_and_cross[3] && (
                                          <img
                                            src={
                                              reportData.documents[
                                                documentsObject[
                                                "Aadhar Card"
                                                ]
                                              ].corrected_ocr
                                                .tick_and_cross[3].isTick
                                                ? GreenTick
                                                : CloseIcon
                                            }
                                            width="20"
                                            alt=""
                                          />
                                        )}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                              <td
                                width="260"
                                style={{ padding: "0px 0px 0px 10px" }}
                              >
                                <table
                                  className="gray-table-rows bg-f8"
                                  width="100%"
                                >
                                  <tr>
                                    <td
                                      width="38%"
                                      style={{ paddingRight: "0px" }}
                                    >
                                      Gender
                                    </td>
                                    <td width="2%" align="center">
                                      :
                                    </td>
                                    <td width="55%">
                                      {" "}
                                      {
                                        aadhaarData.corrected_ocr.gender
                                      }
                                    </td>
                                    <td
                                      align="right"
                                      width="5%"
                                      style={{
                                        paddingRight: "10px",
                                        paddingLeft: "0px",
                                      }}
                                    >
                                      {aadhaarData?.corrected_ocr
                                        ?.tick_and_cross && aadhaarData?.corrected_ocr
                                          ?.tick_and_cross[1] && (
                                          <img
                                            src={
                                              aadhaarData.corrected_ocr
                                                .tick_and_cross[1].isTick
                                                ? GreenTick
                                                : CloseIcon
                                            }
                                            width="20"
                                            alt=""
                                          />
                                        )}
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>

                          <table
                            className="gray-table-rows bg-f8"
                            width="560"
                            style={{ marginTop: "10px" }}
                          >
                            <tr>
                              <td
                                width="40%"
                                style={{ paddingRight: "0px" }}
                              >
                                Aadhaar Upload Date-Time
                              </td>
                              <td
                                width="2%"
                                align="center"
                                style={{ padding: "0px" }}
                              >
                                :
                              </td>
                              <td width="53%">
                                {isMigrated ? moment(
                                  reportData.session.ended_at
                                ).format("DD MMM YYYY LT") : moment(
                                  aadhaarData.created_at
                                ).format("DD MMM YYYY LT")}
                              </td>
                            </tr>
                          </table>
                        </td>
                      )}
                    </tr>
                  </table>
                )}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  )
}

export default AadharData