import React from "react";
import Moment from "react-moment";
import SearchAndSort from "../searchAndSort/SearchAndSort";
import SearchBar from "../searchBar/SearchBar";
class LiveCases extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchBar: false,
        };
    }

    toggleSearchClick = () => {
        const { showSearchBar } = this.state;
        this.setState({ showSearchBar: !showSearchBar });
    };
    render() {
        const { dataArray, searchKeyObject } = this.props;
        const { showSearchBar } = this.state;
        return (
            <div className="col-md-12">
                <div className="ml-30 mr-30 mt-3">
                    <SearchAndSort
                        toggleSearchClick={this.toggleSearchClick}
                        handleSort={this.props.handleSort}
                        getSearchObject={this.props.getSearchObject}
                        searchKeyObject={searchKeyObject}
                    />
                    {showSearchBar ? (
                        <SearchBar
                            datePrefix={"Cancelled"}
                            getSearchObject={this.props.getSearchObject}
                            searchKeyObject={searchKeyObject}
                        />
                    ) : (
                        <div className="row mt-1">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table sml-blue-bg-header bg-sky-blue mb-1">
                                            <thead className="all-head-center">
                                                <tr>
                                                    <th width="14%">Case name</th>
                                                    <th width="14%">Case ID</th>
                                                    <th width="14%">Process</th>
                                                    <th width="14%">Case Tag</th>
                                                    <th width="14%">Created At</th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                                {dataArray.map((item, index) => {
                                    return <div className="row">
                                        <div className="col-md-12">
                                            <div className="tbl-row">
                                                <table className="table mb-0 sml-blue-bg-header table-striped odd-even-tbl roboto-regular">
                                                    <tbody className="all-body-center pt-7-pb-7-td">
                                                        <tr>
                                                            <td width="14%">{item.name}</td>
                                                            <td width="14%">{item.id}</td>
                                                            <td width="14%">{item?.process_details?.name}</td>
                                                            <td width="14%">{item.case_tag}</td>
                                                            <td width="14%"> <Moment format="DD MMM YYYY LT">{item.created_at}</Moment></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default LiveCases;
