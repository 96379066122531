import React, { useEffect } from "react";
import "./feedback.scss";
import Loader from "../loader/Loader";
import { useHistory } from "react-router";

const Feedback = ({
  isFeedback = false,
  closeModal,
  callback,
  id,
  loading,
  isAuditor = false,
  customerJoind,
  isLastStep
}) => {
  const [comment, setComment] = React.useState("");
  const [commentLength, setCommentLength] = React.useState(0);
  let history = useHistory();
  function getComment(commentstr) {
    setCommentLength(commentstr.length);
    setComment(commentstr);
  }
  if (loading) {
    return (
      <div
        className={isFeedback ? "modal fade show" : "modal fade"}
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: isFeedback ? "block" : "none", zIndex: 9999999 }}
      >
        <Loader />
      </div>
    );
  }
  return (
    <div
      className={isFeedback ? "modal fade show" : "modal fade"}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{ display: isFeedback ? "block" : "none", zIndex: 9999999 }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Comment
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                closeModal();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body" style={{ textAlign: "left" }}>
            <span className="feedback-header">Comment:</span>
            <span className="feedback-counter">
              {commentLength + "/" + "249"}
            </span>
            <div>
              <textarea
                className={`form-control transparent-input text-primary input-section w-100`}
                onChange={(e) => getComment(e.target.value)}
                value={comment}
                maxLength={249}
                spellCheck="true"
              ></textarea>
            </div>
          </div>
          <div
            className="modal-footer"
            style={{ justifyContent: "space-between" }}
          >
            {/* <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </button> */}
            {!isAuditor ? (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ background: "red" }}
                  onClick={() => {
                    callback(comment, false);
                  }}
                  disabled={!comment || loading}
                >
                  Case Incomplete
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    callback(comment, true);
                  }}
                  disabled={!comment || !customerJoind || loading }
                >
                  Case Complete
                </button>
              </React.Fragment>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  callback(comment, true);
                }}
                disabled={!comment || loading}
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
