import React from 'react'
import { useSelector } from 'react-redux'

export default function NewPrivacyPolicy() {
    let { clientName } = useSelector(state => state.login)
    return (
        <div className="wrapper light-blue-customer-template privacy-policy-page page-bg-img">
            <header>
                <nav className="navbar navbar-expand-lg h-100 navbar-light bh-white">
                    <div className="float-left">
                        {/* <a href="#" className="text-white mr-2">
                            <i className="fas fa-chevron-left"></i>
                        </a> */}
                        <i className="fas fa-video text-white"></i>
                    </div>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a href="#" className="nav-link"></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <section className="m-auto">
                <div className="container">
                    <div className="row mt-3 mb-3">
                        <div className="col-md-12 col-sm-12 col-12">
                            <div className="overlap-box">
                                <div className="inner-box">
                                    <div className="privacy_policy_cntr">
                                        <div className="heading-section mb-3 text-center">
                                            <p className="heading">Privacy Notice</p>
                                            <label className="version">(Last Updated on : 09-10-2024)</label>
                                        </div>
                                        <div className="content-section">
                                            <div className="accordion" id="privacyPolicyAccordion">
                                                <div className="card">
                                                    <div className="card-header" id="headingOne">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Introduction
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>AuthBridge is engaged to provide verification services (the “Services”) to its clients
                                                                (“Clients”).
                                                                For the purpose of compliance with relevant data privacy laws, the Client is the Data
                                                                Controller/Fiduciary and unless otherwise notified, AuthBridge shall be the Data Processor
                                                                and undertake processing of the personal data on its client’s behalf and under its
                                                                instructions.</p>
                                                            <p>The purpose of this privacy statement is to explain how AuthBridge Research Services
                                                                Private Limited &amp; its subsidiaries (“AuthBridge” “we,” “us,” or “our,” if not explicitly referring
                                                                to one company)) collect, process, store, use, transfer, and protect your Personal Data (as
                                                                defined herein after) for providing Video-Customer Identification Process (V-CIP) and Video
                                                                Personal Discussion (VPD) service to our clients’ customers via this V-KYC application.</p>
                                                            <p>AuthBridge is committed to protecting the privacy and confidentiality of Personal Data about
                                                                its Clients, candidates, its employees, partners and customers and ensuring that any
                                                                Personal Data including the Personal Data supplied by/ collected on behalf of its clients or
                                                                otherwise generated by its business activities is collected and processed fairly and lawfully.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                Scope &amp; Applicability
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>This Statement applies to AuthBridge, AuthBridge’s clients or individuals who furnish the
                                                                Personal Data to AuthBridge’s client or on behalf of AuthBridge’s client, directly submit to
                                                                AuthBridge on this platform for customer onboarding via Video-Customer Identification
                                                                Process (V-CIP) on behalf of the client..</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingThree">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                Definitions
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p><strong>Applicable Data Protection Law::</strong> Applicable Data Protection Laws means the relevant
                                                                local and/ or international laws/ regulations basis the jurisdiction where the individual
                                                                resides or located, whose personal data is processed by an organisation. Some of the
                                                                applicable data privacy laws to AuthBridge are General Data Protection Regulation
                                                                (GDPR) and Digital Personal Data Protection Act (DPDPA).</p>
                                                            <p><strong>Personal Data:</strong> Personal Data is any data relating to an identified or identifiable natural
                                                                person. This Personal Data may be further categorised as below, basis the jurisdictional
                                                                Data Protection Laws:
                                                                <p className='ml-3'><strong>Sensitive Personal Data:</strong> Sensitive Personal Data is a specific set of “special
                                                                    categories” that must be treated with extra security.</p>
                                                            </p>
                                                            <p><strong>Data subject/ Data Principal/ Individual:</strong> Means any identified or identifiable natural
                                                                person; an identifiable person is one who can be identified, directly or indirectly, by
                                                                reference to an identification number or to one or more factors specific to the person’s
                                                                physical, physiological, mental, economic, cultural or social identity. This may include
                                                                the below, as per the jurisdictional Data Privacy Law:
                                                                <p className='ml-3 mb-0'>- a child, includes the parents or lawful guardian of such a child;</p>
                                                                <p className='ml-3 mb-0'>- a person with disability, includes her lawful guardian, acting on her behalf.</p>
                                                                <p>For the purpose of this notice, Data subject is the Customer whose verification is to be
                                                                    done for such client’s services.</p>

                                                            </p>
                                                            <p><strong>Processing:</strong> Means any operation or set of operations that is performed upon Personal
                                                                Data or on sets of Personal Data, whether or not by automatic means, such as
                                                                collection, recording, organization, structuring, storage, adaptation, alteration, retrieval,
                                                                consultation, use, transfer, disclosure by transmission, dissemination or otherwise
                                                                making available, alignment or combination, blocking, restriction, erasure or
                                                                destruction.</p>
                                                            <p>
                                                                <strong>Data Controller/ Fiduciary:</strong> Means a person, company or other body which determines
                                                                the purposes and means of processing the personal information.
                                                            </p>
                                                            <p><strong>Consent:</strong> Means any freely given, specific, informed and unambiguous indication of an
                                                                individual permission by which he or she signifies/ indicates agreement to the
                                                                processing of personal data relating to him or her.</p>
                                                            <p><strong>Data Processor:</strong> Means a person, company or other body who processes data on
                                                                behalf of Data Controller/ FIduciary.</p>
                                                            <p><strong>Sub-processor:</strong> Means a person, company or other body used by the Data processor
                                                                to assist in its processing of personal data for a controller.</p>
                                                            <p><strong>Personal Data breach:</strong> Means a security event leading to the accidental, or unlawful
                                                                destruction, loss, alteration, unauthorized disclosure of, or access to personal
                                                                information.</p>
                                                            <p><strong>V-CIP:</strong> Video-based Customer identification Process as envisaged by Reserve Bank of
                                                                India (RBI) for Customer identification.</p>
                                                            <p><strong>VPD:</strong> Video-based Personal discussion for Customer identification.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingFour">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                                What type of Personal Data is collected during V-CIP?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Personal Data is collected/received to Onboard customers using RBI compliant V-CIP solution.</p>
                                                            <p>The Personal Data collected will include:</p>
                                                            <ul>
                                                                <li>Individual name, Date of Birth</li>
                                                                <li>Contact number (mobile), E-mail ID.</li>
                                                                <li>Address details.</li>
                                                                <li>Identity details/ document (passport/Driving License/PAN card/Voter card/Aadhaar).</li>
                                                                <li>IP address, GPS</li>
                                                                <li>Audio-video recording</li>
                                                            </ul>
                                                            <p>Sensitive Personal Data will include:</p>
                                                            <ul>
                                                                <li>Facial image capture for face recognition.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingFive">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                                How does AuthBridge obtain Personal Data?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Personal Data is obtained:</p>
                                                            <ul>
                                                                <li>during audio-video interaction with the Data Subject</li>
                                                                <li>by extracting the information from the Verification documents shared/uploaded by
                                                                    the customer directly on the VKYC platform</li>
                                                                <li>from the photograph taken by the Data Subject</li>
                                                                <li>by screenshot and</li>
                                                                <li>by OTP based Aadhar eKYC (where authorized).</li>
                                                            </ul>
                                                            <p>Below Personal Data is also obtained after accessing the Data Subject’s device during the
                                                                process:</p>
                                                            <ul>
                                                                <li>Data Subject’s location confirmed by Geolocation tagging,</li>
                                                                <li>Camera access for video and</li>
                                                                <li>Microphone access for the audio of the customer.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingSix">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                                How is consent obtained from the data subject/ data principal?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Consent is obtained directly from the Data Subject on this platform, whose video-based
                                                                verification is to be performed for the client’s purposes.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingSeven">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                                What purpose does AuthBridge use the Personal Data (it holds) for?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>In general, our client (i.e., the entity for which you are the customer) is the Data Controller/
                                                                Fiduciary of personal data and determines the means and purposes for processing your
                                                                personal data.</p>
                                                            <p>AuthBridge processes and discloses Personal Data of the data subject for providing video
                                                                based verification in line with clients’ contractual obligations.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingEight">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                                On what legal basis AuthBridge process the Personal Data?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>Our client determines the lawful basis for such processing being Data Controller/Fiduciary.
                                                                AuthBridge strictly processes the data in accordance with its client&#39;s contractual
                                                                instructions.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingNine">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                                Does AuthBridge transfer the Personal Data?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseNine" className="collapse" aria-labelledby="headingNine" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>AuthBridge, with partnerships globally, may need to transfer individual’s Personal Data to
                                                                third party service providers including overseas&#39; partners/ entities, verification sources as
                                                                necessary for the performance of a lawful contract in accordance with its clients. Any such
                                                                information transferred shall be subject to appropriate data privacy regulations and shall be
                                                                strictly in accordance with the contractual obligations as agreed between us and our clients.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTen">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                                To whom the Personal Data collected is disclosed/ transferred?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTen" className="collapse" aria-labelledby="headingTen" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>AuthBridge does not disclose Personal Data to any third party unless such disclosures
                                                                would be necessary for AuthBridge provision of its business functions or for the provision of
                                                                the service to the Client(s).</p>
                                                            <p>Such necessary disclosures would occur strictly in accordance with applicable laws and
                                                                may include:</p>
                                                            <p className='ml-3'>- Agents from Data Controller’s/ Fiduciary’s (AuthBridge’ clients) have access to the
                                                                Personal Data as communicated by such Entity pursuant to a written contract
                                                                between Data Processor and the Client.</p>
                                                            <p className='ml-3'>- AuthBridge’s third party service providers/ verification source for providing the
                                                                support in aiding the V-CIP process.</p>
                                                            <p className='ml-3'>- Where AuthBridge is under an obligation to disclose Personal Data to any
                                                                governmental or statutory body to comply with applicable laws, regulations,
                                                                regulatory requests/notices in the public interest.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingEleven">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                                How do we protect the Personal Data we hold?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseEleven" className="collapse" aria-labelledby="headingEleven" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <ul>
                                                                <li>AuthBridge is certified to ISO/IEC 27001:2013 and this international standard defines
                                                                    the requirements for an Information Security Management System (ISMS) and is
                                                                    also SoC-2 Type II certified. AuthBridge’s processes and security controls provide an
                                                                    effective framework for protecting our clients’ and our business information, including
                                                                    personal data.</li>
                                                                <li>We maintain organizational and technical measures for all the personal data we
                                                                    hold. We have protocols, controls, and relevant policies; procedures; and guidelines
                                                                    to maintain these controls, considering the risks associated with the categories of
                                                                    personal data and the processing we undertake.</li>
                                                                <li>We regularly monitor our systems to mitigate possible vulnerabilities and attacks.
                                                                    However, we cannot guarantee or warrant the security of any information transmitted
                                                                    via our websites.</li>
                                                                <li>Our website and the server on which it is hosted is at AWS Mumbai. There are
                                                                    reasonable and appropriate controls also at AWS to secure your data against any
                                                                    accidental or unlawful loss, access or disclosure. For more details
                                                                    visit https://aws.amazon.com/privacy/</li>
                                                                <li>We employ procedures, including contractual obligations, and require all third parties
                                                                    to respect the security of personal data about you and to treat it in accordance with
                                                                    the law. We do not grant permission for our third-party service providers to use
                                                                    personal data about you for their own purposes and only grant permission for them
                                                                    to process personal data about you for specified purposes and in accordance with
                                                                    our instructions.</li>

                                                            </ul>
                                                            <p>Additionally, following are the controls that AuthBridge ensures in accordance with the RBI
                                                                Guidelines-</p>
                                                            <ul>
                                                                <li>End to End encryption.</li>
                                                                <li>SSL encryptions for client-server communication.</li>
                                                                <li>Video interaction is securely stored under encryption protocols by AWS.</li>
                                                                <li>API integrations also use encryption protocols.</li>
                                                                <li>Aadhar number is redacted for data privacy purposes.</li>
                                                                <li>Artificial intelligence and face matching technology for accurate face match to
                                                                    ensure customer is the same person in the ID.</li>
                                                                <li>During offline Aadhar verification, it is ensured that the XML file is not older than 3
                                                                    days.</li>
                                                                <li>Geo-tagging is done to ensure Customer is in India.</li>
                                                                <li>Movement based Liveness check.</li>


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingTwelve">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                                Rights for Data Subjects
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwelve" className="collapse" aria-labelledby="headingTwelve" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>You have the following rights with respect to your Personal Data that we process, subject to
                                                                the conditions and restrictions set out under the applicable laws and basis the jurisdiction in
                                                                which you reside.</p>
                                                            <ul>
                                                                <li><strong>Right to access:</strong> You are entitled to obtain a copy of your personal information,
                                                                    together with an explanation of the categories of data being processed, the purposes
                                                                    of such processing, and the details of third parties to whom the data may have been
                                                                    disclosed.</li>
                                                                <li><strong>Right to rectification:</strong> You are entitled to correct or update your personal
                                                                    information available with us.</li>
                                                                <li><strong>Right to erasure:</strong> You are entitled to get your data deleted from our records.</li>
                                                                <li><strong>Right to object to and / or restrict processing:</strong> You have the right to object to and
                                                                    / or restrict the processing or sharing of your data in some circumstances such as
                                                                    restrict the processing of personal data for criminal checks, or for direct marketing
                                                                    purposes.</li>
                                                                <li><strong>Right to data portability:</strong> You are entitled to obtain and reuse your personal
                                                                    information. You can either obtain the information from us and provide it to a third
                                                                    party or ask us to transfer your personal information directly to a third party.</li>
                                                                <li><strong>Right to withdraw or opt-out:</strong> You have right to withdraw your consent for any or all
                                                                    of the purposes for which your personal data has been collected provided at any
                                                                    time by contacting us.</li>
                                                                <li><strong>Right to not be discriminated</strong> against for exercising your individual rights regarding
                                                                    your personal data.</li>
                                                                <li><strong>Right to nominate</strong> (in the manner prescribed by the Central govt.) any other
                                                                    individual to exercise the above-mentioned rights, in the event of the death or
                                                                    incapacity (unsoundness of mind or infirmity of body) of the data principal.</li>
                                                                <li><strong>Right to complain and obtain redressal:</strong> You have the right to lodge a complaint
                                                                    with the competent supervisory authority, depending upon your jurisdiction, to obtain
                                                                    redressal.</li>
                                                            </ul>
                                                            <p>Under privacy legislation your rights are exercisable against the Data Controller/
                                                                Fiduciary.i.e, AuthBridge’s Client and therefore you should direct your requests to them at
                                                                the address they provide to you.</p>
                                                            <p>On receiving the communications from its clients about your request, AuthBridge will act
                                                                upon the same in accordance with the applicable law.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header" id="headingThirteen">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                                Children’s Privacy
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThirteen" className="collapse" aria-labelledby="headingThirteen" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>AuthBridge does not undertake processing of personal data belonging to a minor (as
                                                                defined under the applicable data privacy regulations), expect in cases, where its clients
                                                                have obtained due consent from the parent/lawful guardian of the minor in accordance with
                                                                the applicable regulations.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card">
                                                    <div className="card-header" id="headingFourteen">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                                How long the Personal Data is retained?
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThirteen" className="collapse" aria-labelledby="headingFourteen" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>As AuthBridge collects your Personal Data only on behalf of its client, it is retained as per
                                                                the retention period agreed with such client by way of a written agreement.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card">
                                                    <div className="card-header" id="headingFifteen">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                                Contact Us
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThirteen" className="collapse" aria-labelledby="headingFifteen" data-parent="#privacyPolicyAccordion">
                                                        <div className="card-body">
                                                            <p>We commit to handle your Personal Data in a way that provides you trust and confidence.
                                                                However, if at any time you have concerns over the handling of your Personal Data you are
                                                                encouraged to contact your employer/AuthBridge&#39;s client you have engagement with.</p>
                                                            <p>If you wish to contact AuthBridge for any privacy related query/concern, then please send
                                                                email at privacy@authbridge.com Or mail to:</p>
                                                            <p className='mb-0'><strong>AuthBridge’s Data Privacy Office (Dept-Compliance)</strong></p>
                                                            <p className='mb-0'>AuthBridge Research Services Pvt. Ltd.</p>
                                                            <p className='mb-0'>Plot No. 123, II Floor, Udyog Vihar,</p>
                                                            <p className='mb-0'>Phase IV – Gurgaon – 122 015</p>
                                                            <p className='mb-0'>Haryana, India</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
