import React from 'react'
import { useSelector } from "react-redux"
import GreenTick2 from "../../assets/icons/green-tick-2.png";
import { FaceMatchTypes } from '../../constants'
function FaceMatch({ reportData, step, isVPD }) {
    let { customers } = reportData
    const { facematch_score_type } = useSelector(state => state.login.clientInfo)
    return (
        <table
            width="100%"
            style={{ border: "1px solid #b1b1b1" }}
            className={!isVPD ? "bg-white mt-2" : "m-auto bg-white"}
        >
            <tr>
                <td style={{ padding: '15px' }}>
                    <table width="100%">
                        <tr>
                            <td style={{ padding: "0px 15px 0px 0px" }}>
                                <h4>{step}. Face Match</h4>
                            </td>
                        </tr>
                    </table>
                    {customers && customers.map((item, cIndex) => {
                        return <table
                            className="container-tbl"
                            width="100%"
                            style={{ marginTop: "0px", marginBottom: "0px" }}
                        >
                            <tr>
                                <td
                                    style={{
                                        padding: "15px",
                                        paddingLeft: "0px",
                                        paddingRight: "0px",
                                        paddingTop: "0px",
                                    }}
                                >
                                    {facematch_score_type.map((fItem, fIndex) => {
                                        if (!FaceMatchTypes[fItem]) return null
                                        return <table
                                            width="100%"
                                            className="bg-white"
                                            style={{ marginTop: "15px" }}
                                        >
                                            <tr>
                                                <td
                                                    valign="top"
                                                    style={{ padding: "0px 0px 0px 10px" }}
                                                >
                                                    <table
                                                        className="gray-table-rows p-10-15 bg-f8"
                                                        width="100%"
                                                    >
                                                        <tr>
                                                            <td
                                                                width="40%"
                                                                style={{
                                                                    padding: "5px 20px !important",
                                                                    textTransform: 'capitalize'
                                                                }}
                                                            >
                                                                {FaceMatchTypes[fItem]?.replaceAll('_', ' ').toLowerCase()}
                                                            </td>
                                                            <td
                                                                width="2%"
                                                                align="center"
                                                                style={{ padding: "0px" }}
                                                            >
                                                                :
                                                            </td>
                                                            <td width="12%">
                                                                {item[fItem === '1' ? 'face_match' : FaceMatchTypes[fItem]] || 0 }
                                                            </td>
                                                            <td
                                                                align="right"
                                                                width="48%"
                                                                valign="middle"
                                                                style={{ padding: "0px" }}
                                                            >
                                                                <table width="100%">
                                                                    <tr>
                                                                        <td
                                                                            width="20"
                                                                            style={{
                                                                                padding: "0px",
                                                                                paddingLeft: "20px",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={GreenTick2}
                                                                                width="20"
                                                                                alt=""
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                paddingLeft: "10px",
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    color: "#24a749",
                                                                                    fontStyle: "italic",
                                                                                }}
                                                                            >
                                                                                Verified by the agent
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    })
                                    }
                                </td>
                            </tr>
                        </table>
                    })}
                </td>
            </tr>
        </table>
    )
}

export default FaceMatch