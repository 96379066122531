import React, { Component } from "react";
import MultiUserDocList from "../multiUserDocList/MultiUserDocList";
import Questionnaire from "../questionnaire/Questionnaire";
import api from "../../apiBaseConfig";
import { NOTES } from "../../apiurl";
import MultiScreenShot from "../multiScreenShot/MultiScreenShot";
import AddParticipant from "../addParticipant/AddParticipant";

class MultiUserClientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: 5,
      activeIndex: 0,
      nextDisabled: false,
      backDisabled: true,
      hideNext: false,
      documents: {},
      note: "",
      noteId: null,
      connections: 0,
      questionnaire: [],
      subscriber: props.subscriber.map((sub) => {
        sub.screenshot = props.processDoc.reduce((allDoc, doc) => {
          const docM = {
            name: doc.name,
            id: doc.id,
            document_upload_event: doc.document_upload_event,
            userId: sub.userId,
          };
          allDoc[doc.doc_key] = allDoc[doc.doc_key]
            ? [...allDoc[doc.doc_key], docM]
            : [docM];
          return allDoc;
        }, {});
        return sub;
      }),
      
    };
  }

  componentDidMount = async () => {
    let { data } = await api.get(NOTES, {
      params: {
        case: this.props.caseId,
      },
    });
    let note = data.length ? data[data.length - 1].note : "";
    let noteId = data.length ? data[data.length - 1].id : null;
    this.setState({ note, noteId });
  };

  handleScreenshot = (subscriber) => {
    let nextDisabled = false;
    for (var i in subscriber) {
      for (var j in subscriber[i].screenshot) {
        for(var k in subscriber[i].screenshot[j]){
          if (!subscriber[i].screenshot[j][k].image) {
              nextDisabled = true 
              break;
          }
        }
      }
    }
    this.setState({ subscriber: subscriber, nextDisabled: nextDisabled });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      subscriber: nextProps.subscriber.map((sub) => {
        let prevSub = this.state.subscriber.find(
          (preSub) => preSub.userId === sub.userId
        );
        if (prevSub) {
          sub.screenshot = prevSub.screenshot;
        } else {
          sub.screenshot = nextProps.processDoc.reduce((allDoc, doc) => {
            const docM = {
              name: doc.name,
              id: doc.id,
              document_upload_event: doc.document_upload_event,
              userId: sub.userId,
            };
            allDoc[doc.doc_key] = allDoc[doc.doc_key]
              ? [...allDoc[doc.doc_key], docM]
              : [docM];
            return allDoc;
          }, {});
        }
        return sub;
      }),
    });
  }

  componentWillUnmount() {
    this.saveNote();
  }

  saveNote = async () => {
    let { note, noteId } = this.state;
    let response;
    try {
      if (noteId) {
        response = await api.patch(NOTES + `${noteId}/`, {
          note: note,
        });
      } else {
        response = await api.post(NOTES, {
          note: note,
          case: this.props.caseId,
        });
      }
      this.setState({ noteId: response.data.id });
    } catch (e) {
      console.log(e);
    }
  };

  handleBack = () => {
    let activeIndex = this.state.activeIndex;
    activeIndex--;
    let backDisabled = activeIndex === 0 ? true : false;
    let hideNext = activeIndex >= this.state.slides - 1 ? true : false;

    this.setState({
      activeIndex: activeIndex,
      backDisabled: backDisabled,
      nextDisabled: false,
      hideNext,
    });
  };

  handleNext = () => {
    let { activeIndex, slides } = this.state;
    if (activeIndex + 1 < slides) {
      activeIndex++;
    }
    let hideNext = activeIndex >= slides - 1 ? true : false;
    let nextDisabled = activeIndex >= slides - 1 ? true : false;
    if (activeIndex === 2) {
      nextDisabled = !this.state.questionnaire.reduce(
        (next, current) => next && current.answer && !current.hasError,
        true
      );
    }
    if (activeIndex === 3) {
      nextDisabled = true;
      const noOfScreenshots = this.state.subscriber.length * 4;
      let totalCount = 0;
      for (var i in this.state.subscriber) {
        let screenshotCount = 0;
        for (var j in this.state.subscriber[i].screenshot.screenshot) {
          if (this.state.subscriber[i].screenshot.screenshot[j].image) {
            screenshotCount = screenshotCount + 1;
            if (screenshotCount == 4) {
              break;
            }
          }
        }
        totalCount = totalCount + screenshotCount;
      }
      if (noOfScreenshots == totalCount) {
        nextDisabled = false;
      }
    }
    this.setState({
      ...this.state,
      activeIndex: activeIndex,
      backDisabled: false,
      hideNext,
      nextDisabled: nextDisabled,
    });
  };
  componentDidUpdate(){
    if(this.state.activeIndex == 4) this.props.setLastStep(true)
  }

  render() {
    const {
      activeIndex,
      backDisabled,
      note,
      questionnaire,
      subscriber,
      nextDisabled,
      hideNext,
    } = this.state;

    return (
      <React.Fragment>
        <div class="bg-light p-2 rounded border border-2 brdr-d4 scrollbale-box">
          <div class="inner">
            {activeIndex === 0 && (
              <AddParticipant
                caseId={this.props.caseId}
                allConnections={
                  this.props.allConnections.length + this.state.connections
                }
                addConnection={() =>
                  this.setState({ connections: this.state.connections + 1 })
                }
              />
            )}

            {activeIndex === 1 && (
              <MultiUserDocList
                sessionId={this.props.sessionId}
                caseName={this.props.caseName}
                userLocation={this.props.userLocation}
                customerJoind={this.props.customerJoind}
                connectionId={this.props.connectionId}
                userId={this.props.userId}
                refreshMultiDocList={this.props.refreshMultiDocList}
                // takeScreenShot={takeScreenShot}
                // screenShot={screenShot}
              />
            )}

            {activeIndex === 2 && (
              <div class="steps-box step1 mb-2">
                <h5 class="txt-blue ltr-spc-1">Questionnaire</h5>
                <h6>All fields are compulsory</h6>
                <hr class="dashed-brdr brdr-blue" />

                <Questionnaire
                  questionnaire={questionnaire}
                  connectionId={this.props.connectionId}
                  processId={this.props.processId}
                  multi={true}
                  setQuestions={(ques) => {
                    const nextDisabled = !ques.reduce(
                      (next, current) =>
                        next && current.answer && !current.hasError,
                      true
                    );
                    this.setState({
                      ...this.state,
                      questionnaire: ques,
                      nextDisabled,
                    });
                  }}
                  userId={this.props.userId}
                  caseId={this.props.sessionId}
                  customerId={this.props.allConnections[0].id}
                />
              </div>
            )}

            {activeIndex === 3 && (
              <div class="steps-box step1 mb-2">
                <h5 class="txt-blue ltr-spc-1">Screenshot</h5>

                <hr class="dashed-brdr brdr-blue" />
                <MultiScreenShot
                  takeScreenShot={this.props.takeScreenShot}
                  subscriber={subscriber}
                  caseId={this.props.caseId}
                  userId={this.props.userId}
                  handleScreenshot={this.handleScreenshot}
                />
              </div>
            )}

            {activeIndex === 4 && (
              <div class="steps-box step1 mb-2">
                <h5 class="txt-blue ltr-spc-1">Complete</h5>

                <hr class="dashed-brdr brdr-blue" />
                <div className="border rounded p-4 bg-f9 mt-3">
                  <h2 className="documents-verified-heading">
                    All steps completed.
                  </h2>
                  <h6>
                    {" "}
                    Click on Back to reverify details or end the call to submit
                    the case
                  </h6>
                </div>
              </div>
            )}
          </div>
        </div>

        <div class="rounded border-primary border mt-3 mb-3">
          <div class="p-3 border-bottom">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <span class="text-primary text-uppercase">Notes</span>
              </div>
            </div>
          </div>
          <div class="border-bottom">
            <textarea
              onChange={(e) => this.setState({ note: e.target.value })}
              value={note}
              class="form-control rounded-0 border-0 fnt-sml notes-textarea"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
          <div class="common-chat p-2 d-block text-right">
            <button
              onClick={this.saveNote}
              class="btn btn-outline-primary btn-sm fnt-sml"
            >
              Save
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button
              disabled={backDisabled}
              onClick={this.handleBack}
              class={`btn btn-custom ${
                backDisabled ? "btn-secondary" : "btn-primary"
              } text-uppercase`}
            >
              Back
            </button>
          </div>
          {!hideNext && (
            <div class="col text-right">
              <button
                // disabled={!valid}
                // onClick={handleNext}

                disabled={nextDisabled}
                onClick={() => {
                  this.handleNext();
                }}
                class={`btn btn-custom ${
                  nextDisabled ? "btn-secondary" : "btn-primary"
                } text-uppercase`}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default MultiUserClientInfo;
