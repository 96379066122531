import React from 'react'
import GreenTick2 from "../../assets/icons/green-tick-2.png";
import CloseIcon from "../../assets/icons/close-icon.png";
import GreenTick from "../../assets/icons/green-tick.png";
import moment from "moment";
import { panTandC } from '../../constants';
const nic_id_name = {
    1: 'AADHAAR NUMBER',
    2: 'DRIVING LICENSE',
    3: 'PASSPORT',
    4: 'VOTER CARD'
}
function PanData({ reportData, documentsObject, imageZoom, step, isVPD }) {
    let panDetails = reportData.documents[
        documentsObject["PAN Card"]
    ]
    let isPanNumber = false
    let pan_number = ''
    let NIC_Type = 0
    let NIC_Number = ''

    if (!panDetails) {
        pan_number = reportData?.case?.pan_number
        if (pan_number && pan_number.length > 0) {
            isPanNumber = true
            NIC_Type = reportData?.case?.nic_type || 0
            NIC_Number = reportData?.case?.nic_number || ''
        }
    }
    if (!panDetails && !isPanNumber) return null
    if (!panDetails?.corrected_ocr.tick_and_cross && !isPanNumber) {
        panDetails.corrected_ocr.tick_and_cross = panTandC
    }
    const isMigrated = reportData?.case?.old_db_case_id ? true : false
    if (isPanNumber) {
        return (
            <>
                <table
                    width="100%"
                    style={{ border: "1px solid #b1b1b1" }}
                    className={!isVPD ? "bg-white mt-2" : "m-auto bg-white"}
                >
                    <tr>
                        <td style={{ padding: '15px' }}>
                            <h4>{step}. PAN Verification</h4>

                            <table
                                width="100%"
                                className="bg-white"
                                style={{ marginTop: "15px" }}
                            >
                                <tr>
                                    <td
                                        width="500"
                                        style={{ padding: "0px 10px 0px 0px" }}
                                    >
                                        <table
                                            width="500"
                                            className="gray-table-rows bg-f8"
                                            style={{ overflow: "wrap" }}
                                        >
                                            <tr>
                                                <td
                                                    width="42%"
                                                    style={{ paddingRight: "0px" }}
                                                >
                                                    Pan Number
                                                </td>
                                                <td
                                                    width="2%"
                                                    align="center"
                                                    style={{ padding: "0px" }}
                                                >
                                                    :
                                                </td>
                                                <td width="51%">
                                                    {pan_number}
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr>


                                {NIC_Type != 0 ? <tr>
                                    <td
                                        width="500"
                                        style={{ padding: "0px 10px 0px 0px" }}
                                    >
                                        <table
                                            width="500"
                                            className="gray-table-rows bg-f8"
                                            style={{ overflow: "wrap", marginTop: 15 }}
                                        >
                                            <tr>
                                                <td
                                                    width="42%"
                                                    style={{ paddingRight: "0px" }}
                                                >
                                                    {nic_id_name?.[NIC_Type]}
                                                </td>
                                                <td
                                                    width="2%"
                                                    align="center"
                                                    style={{ padding: "0px" }}
                                                >
                                                    :
                                                </td>
                                                <td width="51%">
                                                    {NIC_Number}
                                                </td>
                                            </tr>

                                        </table>
                                    </td>
                                </tr> : null}

                            </table>
                        </td>
                    </tr>

                </table>
            </>
        )
    }
    return (
        <>
            <table
                width="100%"
                style={{ border: "1px solid #b1b1b1" }}
                className={!isVPD ? "bg-white mt-2" : "m-auto bg-white"}
            >
                <tr>
                    <td style={{ padding: '15px' }}>
                        <h4>{step}. PAN Verification</h4>
                        {documentsObject["PAN Card"] && (
                            <table
                                width="100%"
                                className="bg-white"
                                style={{ marginTop: "15px" }}
                            >
                                <tr>
                                    <td
                                        valign="top"
                                        width="295"
                                        style={{ padding: "0px 0px 0px 0px" }}
                                    >
                                        <table width="100%" className="bg-f8">
                                            <tr>
                                                <td
                                                    align="center"
                                                    style={{
                                                        padding: "20px",
                                                        paddingBottom: "10px",
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            panDetails.front_upload
                                                        }
                                                        crossOrigin="anonymous"
                                                        onClick={() =>
                                                            imageZoom(panDetails.front_upload)
                                                        }
                                                        alt=""
                                                        width="245"
                                                        className="img-fluid rounded"
                                                        style={{ cursor: "pointer" }}
                                                    />

                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    align="center"
                                                    style={{
                                                        fontSize: "9px",
                                                        fontStyle: "italic",
                                                        padding: "0px 20px 33px 20px",
                                                    }}
                                                >
                                                    PAN image as per received via OCR
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    {panDetails
                                        .document_upload_type ===
                                        "digilocker_upload" ? (
                                        <td
                                            valign="top"
                                            style={{ padding: "0px 0px 0px 10px" }}
                                        >
                                            <table
                                                className="green-table-rows"
                                                width="445"
                                                style={{
                                                    backgroundColor: "#ddf2e3",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{
                                                            paddingRight: "0px",
                                                            color: "#24a749",
                                                        }}
                                                    >
                                                        PAN verification status
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td
                                                        width="58%"
                                                        valign="middle"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        <table width="100%">
                                                            <tr>
                                                                <td
                                                                    width="20"
                                                                    style={{
                                                                        padding: "0px",
                                                                        paddingLeft: "10px",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={GreenTick2}
                                                                        width="20"
                                                                        alt=""
                                                                    />
                                                                </td>
                                                                <td style={{ paddingLeft: "10px" }}>
                                                                    <span
                                                                        style={{
                                                                            color: "#24a749",
                                                                            fontStyle: "italic",
                                                                        }}
                                                                    >
                                                                        Pan successfully verified by
                                                                        digi-locker
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="445"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Document Upload Date-Time
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%">
                                                        {isMigrated ? moment(
                                                            reportData.session.ended_at
                                                        ).format("DD MMM YYYY LT")
                                                            :
                                                            moment(
                                                                panDetails.created_at
                                                            ).format("DD MMM YYYY LT")}

                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        <img
                                                            src={GreenTick}
                                                            width="20"
                                                            alt=""
                                                        />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    ) : (
                                        <td
                                            valign="top"
                                            style={{ padding: "0px 0px 0px 10px" }}
                                        >
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="445"
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        PAN Number
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%" align="left">
                                                        {
                                                            panDetails.corrected_ocr?.doc_id
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {panDetails?.corrected_ocr?.tick_and_cross && panDetails.corrected_ocr?.tick_and_cross[2] && (
                                                            <img
                                                                src={
                                                                    panDetails.corrected_ocr?.tick_and_cross[2]
                                                                        .isTick
                                                                        ? GreenTick
                                                                        : CloseIcon
                                                                }
                                                                crossOrigin="anonymous"
                                                                width="20"
                                                                alt=""
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="445"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Name as on PAN
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%" align="left">
                                                        {
                                                            panDetails.corrected_ocr.name
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {panDetails?.corrected_ocr?.tick_and_cross && panDetails?.corrected_ocr?.tick_and_cross[0] && (
                                                            <img
                                                                src={
                                                                    panDetails.corrected_ocr.tick_and_cross[0]
                                                                        .isTick
                                                                        ? GreenTick
                                                                        : CloseIcon
                                                                }
                                                                width="20"
                                                                alt=""
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="445"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Date of birth
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%">
                                                        {
                                                            panDetails.corrected_ocr.dob
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {panDetails?.corrected_ocr.tick_and_cross && (
                                                            <img
                                                                src={
                                                                    panDetails?.corrected_ocr?.tick_and_cross[3]
                                                                        .isTick
                                                                        ? GreenTick
                                                                        : CloseIcon
                                                                }
                                                                width="20"
                                                                alt=""
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="445"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Fathers Name as on PAN
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%">
                                                        {
                                                            panDetails.corrected_ocr.father_name
                                                        }
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    >
                                                        {panDetails?.corrected_ocr?.tick_and_cross && (
                                                            <img
                                                                src={
                                                                    panDetails?.corrected_ocr?.tick_and_cross[1]
                                                                        .isTick
                                                                        ? GreenTick
                                                                        : CloseIcon
                                                                }
                                                                width="20"
                                                                alt=""
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                            <table
                                                className="gray-table-rows bg-f8"
                                                width="445"
                                                style={{ marginTop: "10px" }}
                                            >
                                                <tr>
                                                    <td
                                                        width="37%"
                                                        style={{ paddingRight: "0px" }}
                                                    >
                                                        Document Upload Date-Time
                                                    </td>
                                                    <td
                                                        width="5%"
                                                        align="center"
                                                        style={{ padding: "0px" }}
                                                    >
                                                        :
                                                    </td>
                                                    <td width="43%">
                                                        {isMigrated ? moment(
                                                            reportData.session.ended_at
                                                        ).format("DD MMM YYYY LT")
                                                            :
                                                            moment(
                                                                panDetails.created_at
                                                            ).format("DD MMM YYYY LT")}
                                                    </td>
                                                    <td
                                                        align="right"
                                                        width="15%"
                                                        style={{
                                                            paddingRight: "10px",
                                                            paddingLeft: "0px",
                                                        }}
                                                    ></td>
                                                </tr>
                                            </table>
                                        </td>
                                    )}
                                </tr>
                            </table>
                        )}
                    </td>
                </tr>
            </table>

        </>
    )
}

export default PanData