import { put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "./user.types";
import api from "../../apiBaseConfig";
import {
  UPLOAD,
  OCR,
  VERIFYPAN2,
  UPDATEDOCUMENT,
  VERIFYPAN,
} from "../../apiurl";
import { Toast } from "../../utils/toastutils";

export function* upload({ payload }) {
  try {
    let response;
    let ocrResponse;
    let type;
    console.log(payload);
    // debugger;
    if (payload.type === "PAN") {
      response = yield api.put(UPLOAD, {
        connection: payload.connection,
        case: payload.case,
        front_upload: payload.data,
        document_upload_type: payload.document_upload_type,
        document: payload.documentId, //payload.document,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "before_call",
      });
      if (payload.document_upload_type === "normal_upload") {
        ocrResponse = yield api.post(OCR + `${response.data.id}/`);
        console.log(ocrResponse);
      }
      response = {
        data: {
          image: payload.data,
          document_upload_type: payload.document_upload_type,
          data: ocrResponse ? ocrResponse.data.ocr_data.extracted_ocr : {},
          id: response.data.id,
        },
      };
      type = ActionTypes.UPLOAD_PAN;
    } else if (payload.type === "AADHAR") {
      response = yield api.put(UPLOAD, {
        connection: payload.connection,
        case: payload.case,
        front_upload: payload.data.front.data,
        back_upload: payload.data.back.data,
        document_upload_type: payload.document_upload_type,
        document: payload.documentId, //payload.document,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "before_call",
      });
      if (payload.document_upload_type === "ocr_upload") {
        ocrResponse = yield api.post(OCR + `${response.data.id}/`);
        console.log(ocrResponse);
      }
      response = {
        data: {
          image: payload.data,
          document_upload_type: payload.document_upload_type,
          data: ocrResponse ? ocrResponse.data.ocr_data.extracted_ocr : {},
          id: response.data.id,
          digilocker_data: payload.digilocker_data,
        },
      };
      type = ActionTypes.UPLOAD_AADHAR;
    } else if (payload.type === "DRIVING_LICENSE") {
      response = yield api.put(UPLOAD, {
        connection: payload.connection,
        case: payload.case,
        front_upload: payload.data.front.data,
        back_upload: payload.data.back.data,
        document_upload_type: payload.document_upload_type,
        document: payload.documentId, //payload.document,
        corrected_ocr: {},
        extracted_ocr: {},
        document_upload_event: "before_call",
      });
      if (payload.document_upload_type === "ocr_upload") {
        ocrResponse = yield api.post(OCR + `${response.data.id}/`);
        console.log(ocrResponse);
      }
      response = {
        data: {
          image: payload.data,
          document_upload_type: payload.document_upload_type,
          data: ocrResponse ? ocrResponse.data.ocr_data.extracted_ocr : {},
          id: response.data.id,
        },
      };
      type = ActionTypes.UPLOAD_DL;
    } else {
      let responseFront = yield api.put(UPLOAD, {
        connection: payload.connection,
        case: payload.case,
        front_upload: payload.data.front.data,
        back_upload: payload.data.back.data,
        document: payload.documentId, //payload.data.front.documentId,
        corrected_ocr: {},
        document_upload_type: payload.document_upload_type,
        extracted_ocr: {},
        document_upload_event: "before_call",
      });
      if (payload.document_upload_type === "normal_upload") {
        ocrResponse = yield api.post(OCR + `${response.data.id}/`);
        console.log(ocrResponse);
      }
      response = {
        data: {
          image: payload.data.front.data, //ocrResponse.data.ocr_data.extracted_ocr,
          id: responseFront.data.id,
          document_upload_type: payload.document_upload_type,
          aadharUploaded: payload.document_upload_type === "normal_upload",
          data: payload.data,
        },
      };
      type = ActionTypes.UPLOAD_AADHAR;
    }
    yield put({
      type: type,
      payload: {
        ...response.data,
      },
    });
  } catch (error) {
    console.log("Error during UPLOAD", error);
    setTimeout(() => {
      window.location.reload();
    }, 4000);
    // Toast.error({
    //   description: error.detail ? error.detail : "Upload Fail Please contact your agent",
    //   title: "Error"
    // });
    yield put({
      type: ActionTypes.UPLOAD_FAIL,
    });
  }
}

export function* varifyPan({ payload }) {
  try {
    // api call to save
    if (payload.upload_type === "normal_upload") {
      let verifyResponse = yield api.post(VERIFYPAN2 + `${payload.id}/`);
      if(!verifyResponse){
        yield put({
              type: ActionTypes.PAN_INVALID,
              payload: true
            });
            return;
      }
    }
    // , {
    //   doc_id: payload.doc_id
    // });
    // if (verifyResponse.data.data.status !== 1) {// || verifyResponse.data.data.msg.NameOnTheCard !== payload.data.name) {
    //   yield put({
    //     type: ActionTypes.PAN_INVALID,
    //     payload: true
    //   });
    //   return;
    // }
    let response = yield api.patch(UPDATEDOCUMENT + `${payload.id}/`, {
      corrected_ocr:
        payload.upload_type === "normal_upload" ? payload.data : {},
      is_verified: true,
    });
    yield put({
      type:
        payload.type === "panData"
          ? ActionTypes.VARIFY_PAN
          : ActionTypes.VARIFY_AADHAR,
      payload: { ...payload },
    });
  } catch (error) {
    yield put({
      type:
        payload.type === "panData"
          ? ActionTypes.PAN_INVALID
          : ActionTypes.AADHAR_INVALID,
      payload: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 4000);
    console.log("Error during UPLOAD", error);
  }
}

export function* varifyAadhaar({ payload }) {
  try {
    // api call to save
    // if (payload.upload_type === "normal_upload") {
    //   let verifyResponse = yield api.post(VERIFYPAN2 + `${payload.id}/`);
    // }
    // , {
    //   doc_id: payload.doc_id
    // });
    // if (verifyResponse.data.data.status !== 1) {// || verifyResponse.data.data.msg.NameOnTheCard !== payload.data.name) {
    //   yield put({
    //     type: ActionTypes.PAN_INVALID,
    //     payload: true
    //   });
    //   return;
    // }
    let response = yield api.patch(UPDATEDOCUMENT + `${payload.id}/`, {
      corrected_ocr: payload.upload_type === "ocr_upload" ? payload.data : {},
      is_verified: true,
    });
    yield put({
      type:
        payload.type === "panData"
          ? ActionTypes.VARIFY_PAN
          : ActionTypes.VARIFY_AADHAR,
      payload: { ...payload },
    });
  } catch (error) {
    yield put({
      type:
        payload.type === "panData"
          ? ActionTypes.PAN_INVALID
          : ActionTypes.AADHAR_INVALID,
      payload: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 4000);
    console.log("Error during UPLOAD", error);
  }
}

export function* varifyDL({ payload }) {
  try {
    let response = yield api.patch(UPDATEDOCUMENT + `${payload.id}/`, {
      corrected_ocr: payload.upload_type === "ocr_upload" ? payload.data : {},
      is_verified: true,
    });
    yield put({
      type:
        payload.type === "panData"
          ? ActionTypes.VARIFY_PAN
          : ActionTypes.VARIFY_DL,
      payload: { ...payload },
    });
  } catch (error) {
    yield put({
      type:
        payload.type === "panData"
          ? ActionTypes.PAN_INVALID
          : ActionTypes.DL_INVALID,
      payload: true,
    });
    setTimeout(() => {
      window.location.reload();
    }, 4000);
    console.log("Error during UPLOAD", error);
  }
}

export default function* () {
  yield takeEvery(ActionTypes.UPLOAD, upload);
  yield takeEvery(ActionTypes.VARIFY, varifyPan);
  // yield takeEvery(ActionTypes.VARIFY_AADHAR, varifyAadhaar);
}
