import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import { ReactComponent as VideoIcon } from "../../../assets/icons/video-icon.svg";
import {
  AUTO_RESCHEDULE,
  GET_CASES,
  GET_CASE_CONNECTIONS
} from "../../../apiurl";
import {
  storeAnonymousUserConnectionInfo,
} from "../../state/user.actions";
import _get from "lodash/get";
import { connect, useSelector } from "react-redux";
import api, { instance2 } from "../../../apiBaseConfig";
import Loader from '../../../components/loader/Loader';
import moment from "moment";
import { Toast } from '../../../utils/toastutils';

function UserReschedule(props) {

  const [scheduleTime, setScheduleTime] = useState(null);
  const [agentId, setAgentId] = useState(null);
  const [caseId, setCaseId] = useState(null);
  const [livecase, setLiveCase] = useState(false);
  const [rescheduleAttempt, setRescheduleAttempt] = useState(0)
  const maxReschedule = 3;
  const location = useLocation();
  const history = useHistory();
  const clientConfig = useSelector(state => state.login.clientInfo)

  useEffect(async () => {
    try {
      const userToken = new URLSearchParams(location.search).get('token')
      if (userToken) {
        console.log("validating token");
        validateUserToken(userToken);
      } else {
        if (location.state.scheduleTime) {
          setScheduleTime(location.state.scheduleTime);
          setAgentId(location.state.agent);
          setRescheduleAttempt(location.state.rescheduleAttempt);
        } else {
          const { data } = await api.get(AUTO_RESCHEDULE)
          setScheduleTime(data.schedule_time);
          setAgentId(data.agent);
        }
        location && setCaseId(location.state.caseId);
      }
    } catch (e) {
      console.log(e)
    }
  }, [])

  const cancelKYC = async () => {
    try {
      // const caseId = location.state;
      await api.patch(`${GET_CASES}${caseId}/`, {
        is_active: false
      });
      history.push("/thankyou")
    } catch (e) {
      console.log(e)
    }
  }
  //token validation
  const validateUserToken = async (userToken) => {
    console.log("validating token");
    try {
      let config = {
        headers: {
          AuthorizationX: `Token ${userToken}`,
        },
      };

      const response = await instance2.get(
        `${GET_CASE_CONNECTIONS}${userToken}/`,

        config
      );

      const anonymousUserConnectionInfo = response.data;
      console.log("anonymousUserConnectionInfo", anonymousUserConnectionInfo);
      anonymousUserConnectionInfo.isTokenValid = true;
      setLiveCase(anonymousUserConnectionInfo.case_details.status === "live");
      anonymousUserConnectionInfo.multiUser =
        anonymousUserConnectionInfo.case_details.case_type === "video_kyc"
          ? false
          : true;
      props.storeAnonymousUserConnectionInfo(anonymousUserConnectionInfo); //
      setRescheduleAttempt(_get(
        anonymousUserConnectionInfo,
        "case_details.reschedule_attempt",
        null));
      setCaseId(anonymousUserConnectionInfo.case_details.id);
      setScheduleTime(anonymousUserConnectionInfo.case_details.session_scheduled_time);
      setAgentId(anonymousUserConnectionInfo.case_details.agent);
    } catch (error) {
      console.log("error", error);
      //setTokenValidity(false);
      const anonymousUserConnectionInfo = _get(
        props,
        "anonymousUserConnectionInfo",
        {}
      );
      Toast.error({
        description: _get(error, "response.data.detail", "Please contact your KYC agent"),
        title: "Access Denied",
      });
      anonymousUserConnectionInfo.isTokenValid = false;
      props.storeAnonymousUserConnectionInfo(anonymousUserConnectionInfo); //
      // alert("This meeting link has expired");
    }
  };

  if (agentId === null) {
    return <Loader />
  }


  return (
    <div className="wrapper light-blue-customer-template bg-dark-blue h-100">
      <section className="main-body m-auto" >
        <div className="container-fluid position-relative">
          <div className="row">
            <div className="col-md-12">
              <VideoIcon className="mt-4 mb-4" width="50" height="50" fill="#fff" />
              <h4 className="text-white ltr-spc-1" style={{ lineHeight: '26px' }}>Hi Customer<br /><br />
                Your KYC is scheduled on {moment(scheduleTime).format('Do MMM YYYY')} at {moment(scheduleTime).format('h:mm a')} with our agent
              </h4>
              <h6 className="text-white ltr-spc-1 mt-4 mb-4">if the schedule time doesn't seem right choose from the below options</h6>
              {rescheduleAttempt === maxReschedule - 1 &&
                <h6 className="errorTxt ltr-spc-1 mt-4 mb-4">This is your last reschedule attempt</h6>
              }
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-right">
              {clientConfig?.client_details?.name === "Stashfin" ? null : <button disabled={rescheduleAttempt > maxReschedule - 1} onClick={e => history.push({ pathname: "./reschedule/time", state: livecase })} className="btn btn-block btn-light-blue mb-3 fnt-sml" ref={(node) => { if (node) { node.style.setProperty("background-color", "#349EEF", "important"); } }} style={{ height: '45px', minWidth: '160px' }}>Reschedule for another time</button>}
              <button disabled={rescheduleAttempt > maxReschedule - 1} onClick={e => history.push({ pathname: "./reschedule/agent", state: { agentId, livecase } })} className="btn btn-block btn-light-blue mb-5 fnt-sml" ref={(node) => { if (node) { node.style.setProperty("background-color", "#34C9EF", "important"); } }} style={{ height: '45px', minWidth: '160px' }}>Reschedule with same Agent</button>
              {/* <button onClick={cancelKYC} className="btn btn-block btn-light-blue fnt-sml" ref={(node) => { if (node) { node.style.setProperty("background-color", "#EF3492", "important"); } }} style={{ height: '45px', minWidth: '160px' }}>CANCEL KYC</button> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  anonymousUserConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

const mapDispatchToProps = (dispatch) => ({
  storeAnonymousUserConnectionInfo: (payload) =>
    dispatch(storeAnonymousUserConnectionInfo(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserReschedule);