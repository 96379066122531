import React, { useEffect, useState } from "react";
import ClientInfo from "../../../components/clientInfo/ClientInfo";
import MultiUserDocList from "../../../components/multiUserDocList/MultiUserDocList";
import api from "../../../apiBaseConfig";
import _get from "lodash/get";
import Feedback from "./../../../components/feedback/feedback";
import {
  GET_CASE_CONNECTIONS,
  CREATE_PLATFORM_TOKEN_FOR_USER,
  COMPLETE_SESSION,
  CASE_HISTORY,
  SIGNED_VIDEO_URL,
  CREATE_SESSION_PLATFORM,
  INCOMPLETE_SESSION,
  CREATE_SESSION_DB,
  GET_CASES,
} from "../../../apiurl";
import { useHistory } from "react-router";
import "./VideoCallDashboard.scss";
import MultiUserClientInfo from "../../../components/multiUserClientInfo/MultiUserClientInfo";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration, LogLevel } from 'amazon-chime-sdk-js';
import {
  useLocalVideo,
  useRemoteVideoTileState,
} from "amazon-chime-sdk-component-library-react";
import ChimeVideoRoomComponent from "../../../components/chimeVideoRoomComponent/ChimeVideoRoomComponent";
import Loader from "../../../components/loader/Loader";
import cogoToast from "cogo-toast";

function VideoCallDashboard(props) {
  const history = useHistory();
  const [multiUser, setMultiUser] = useState(false);
  const [isFeedback, setFeedback] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [customerJoind, setCustomerJoind] = useState(false);
  const [userLocation, setUserLocation] = useState("");
  const [multiUserLocation, setMultiUserLocation] = useState({});
  const [subscriber, setSubscriber] = useState([]);
  const [processDoc, setProcessDoc] = useState(null);
  const [processId, setProcessId] = useState(null);
  const [connectionId, setConnectionId] = useState(null);
  const [meetingTokenForUser, setMeetingTokenForUser] = useState(null);
  const [refreshMultiDocList, toggleRefreshMultiDocList] = useState(false);
  const [isChimeMeetingStart, SetMeetingStart] = useState(false);
  const [allConnections, setAllConnections] = useState([]);
  const [caseType, setCaseType] = useState(null);
  const [sessionIdVideoCall, setSessionIdVideoCall] = useState(null);
  const { tiles, tileIdToAttendeeId } = useRemoteVideoTileState();
  const [callSignedURL, setCallSignedURL] = useState(false);
  const [signedURLCount, setSignedURLCount] = useState(1);
  const [caseDetails, setCaseDetails] = useState({})
  const { toggleVideo } = useLocalVideo();
  const meetingManager = useMeetingManager();
  const chimeRef = React.useRef(null)
  const [recordingOn, setRecordingOn] = useState(false)
  const [isLastStep, setLastStep] = useState(false)
  meetingManager.logger.setLogLevel(LogLevel.OFF)
  useEffect(async () => {
    if (!history.location.state) {
      history.push("/dashboard");
      return;
    }
    const { caseId, platform_type, videoCallSessionId } =
      history.location.state;
    setSessionIdVideoCall(videoCallSessionId);
    const response = await api.get(`${GET_CASE_CONNECTIONS}?case=${caseId}`);
    console.log("res123 ", response.data);
    const caseType = _get(response, "data[0].case_details.case_type");

    setCaseType(caseType);
    meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
      const response = await api.get(`${GET_CASE_CONNECTIONS}?case=${caseId}`);
      const allUsers = _get(response, "data[0].case_details.connections", []);
      const tempCaseDetails = _get(response, "data[0].case_details", [])
      setCaseDetails(tempCaseDetails)
      const user =
        allUsers.find((user) => {
          const connnectionDetails = user.platform_connection_details
            ? user.platform_connection_details
            : { Attendee: "" };
          console.log("user info123", connnectionDetails);
          return connnectionDetails.Attendee.AttendeeId === chimeAttendeeId;
        }) || {};

      return {
        name: user.name,
      };
    };
    joinChimeMeeting();

    const allUsers = _get(response, "data[0].case_details.connections", []);
    setAllConnections(allUsers);
    setMultiUser(caseType === "video_kyc" ? false : true);
    const connectionInfo = response.data[0] || {};
    setMultiUserLocation(
      allUsers.reduce((locations, user) => {
        locations = { ...locations, [user.id]: user.location };
        return locations;
      }, {})
    );
    console.log("connection123", connectionInfo);
    setConnectionId(connectionInfo.id);
    setProcessDoc(_get(connectionInfo, "case_details.process_details.documents", []).filter(
      (doc) => doc.is_agent_uploaded && (doc.doc_type === 3 || doc.doc_type === 4) && doc.is_active
    ));
    setProcessId(_get(connectionInfo, "case_details.process_details.id", null));
    setUserLocation(connectionInfo.location || "");
  }, []);

  useEffect(() => {
    fetchUpdatedUserLocation();
  }, [subscriber]);


  const leaveSession = () => {
    setFeedback(true);
    stopMediaPipeLine()
    // meetingManager.leave();
  };
  const sessionsPatchCall = async () => {
    try {
      await api.patch(`${CREATE_SESSION_DB}${sessionIdVideoCall}`);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUpdatedUserLocation = async () => {
    console.log("fetchUpdatedUserLocation function");
    const { caseId } = history.location.state;
    const response = await api.get(`${GET_CASE_CONNECTIONS}?case=${caseId}`);
    const allUsers = _get(response, "data[0].case_details.connections", []);
    setMultiUserLocation(
      allUsers.reduce((locations, user) => {
        locations = { ...locations, [user.id]: user.location };
        console.log(
          "fetchUpdatedUserLocation userid subscriber",
          [user.id],
          subscriber
        );
        subscriber.forEach((sub) => {
          if (sub.userId === user.id) {
            sub.location = user.location;
          }
        });

        return locations;
      }, {})
    );
  };

  function meetingDataAdapter(data) {
    const str = data.split("");
    let arr = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === "'") {
        arr.push(`"`);
      } else {
        arr.push(str[i]);
      }
    }
    const obj = JSON.parse(arr.join(""));
    return obj.Meeting
  }

  const joinChimeMeeting = async () => {
    try {
      const caseId = history.location.state.caseId;

      // Get the meeting details and attendee details
      const response = await api.post(CREATE_SESSION_PLATFORM, {
        case_id: caseId,
      });
      const attendeeResponse = await api.post(`${CREATE_PLATFORM_TOKEN_FOR_USER}${caseId}/`);

      let meetingObject = meetingDataAdapter(response.data.platform_session_details)

      // Initialize the `MeetingSessionConfiguration`
      const meetingSessionConfiguration = new MeetingSessionConfiguration(meetingObject, attendeeResponse.data.Attendee);

      console.log("meetingSessionConfiguration", meetingSessionConfiguration);

      // Use the join API to create a meeting session using the MeetingSessionConfiguration
      await meetingManager.join(meetingSessionConfiguration);
      console.log("meeting joined");


      await meetingManager.start();

      SetMeetingStart(true);
      console.log("meeting started");
      toggleVideo();
    } catch (error) {
      console.log("error", error);
    }
  };

  const takeScreenShot = (subscriber) => {
    // const classId = `ch-remote-video--${subscriber.tileId}`;
    // const videoOuterContainer =
    //   document.getElementsByClassName(classId) || [];
    // const video = videoOuterContainer[0].children[0];
    // const userImg = document.getElementById("userImg-test");
    // const avatar = userImg.getContext("2d");

    // avatar.drawImage(video, 0, 0, 180, 180);

    let imageData = meetingManager.audioVideo.captureVideoTile(subscriber.tileId)
    let base64Image = convertUint8ArrayToBase64(imageData)
    return base64Image
    // return userImg.toDataURL();
  };

  function convertUint8ArrayToBase64(imagedata) {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = imagedata.width;
    canvas.height = imagedata.height;
    ctx.putImageData(imagedata, 0, 0);
    return canvas.toDataURL();
  }

  const completeSession = async (caseId) => {
    console.log("delete here123");

    try {
      const response = await api.delete(COMPLETE_SESSION + caseId + "/", {});
      console.log("Delete Response", response);
      endCallHandler()
    } catch (error) {
      if (window.confirm("Case not completed. Press OK to try again")) {
        await completeSession(caseId);
      }
    }
  };

  const incompleteSession = async () => {
    const caseId = history.location.state.caseId;
    console.log("mark incomplete");
    try {
      const response = await api.post(INCOMPLETE_SESSION + caseId + "/", {});
      console.log("incomplete", response);

      // endCallHandler()
    } catch (error) {
      if (window.confirm("Case not completed. Press OK to try again")) {
        await incompleteSession();
      }
    }
  };
  const endCallHandler = async (callPatchAPI) => {
    const caseId = history.location.state.caseId;
    if (callPatchAPI) {
      await api.patch(`${GET_CASES}${caseId}/`, {
        status: "completed",
      });
    }
    setFeedback(false);
    setLoading(false);

    history.push("/dashboard", {
      caseType,
      status: "completed",
      caseId: caseId,
    });
  };

  const submitComment = async (comment, isCaseComplete) => {
    console.log("submit comment start");
    const caseId = history.location.state.caseId;
    const userId = history.location.state.userId;
    const body = {
      case: caseId,
      user: userId,
      type: "comment",
      re_text: comment,
    };
    try {
      setLoading(true);
      const res = await api.post(CASE_HISTORY, body);
      if (!isCaseComplete) {
        await incompleteSession();
        setFeedback(false);
        setLoading(false);
        if (chimeRef.current) {
          chimeRef.current.sendMeetingIncompleteEvent()
          meetingManager.leave()
        }
        history.push("/dashboard", {
          caseType,
          status: "scheduled",
        });
        return;
      }
      setLoading(true);
      const caseId = history.location.state.caseId;

      await completeSession(caseId);
    } catch (e) {
      setLoading(false);

      console.log(e);
    }
  };

  const getChimeSubscriberName = async (tileId) => {
    const caseId = history.location.state.caseId;

    const attendeeId = tileIdToAttendeeId[tileId];

    const response = await api.get(`${GET_CASE_CONNECTIONS}?case=${caseId}`);
    const allUsers = _get(response, "data[0].case_details.connections", []);
    const curUser =
      allUsers.find((user) => {
        const connnectionDetails = user.platform_connection_details
          ? user.platform_connection_details
          : { Attendee: "" };
        console.log("info123", connnectionDetails);
        return connnectionDetails.Attendee.AttendeeId === attendeeId;
      }) || {};
    return { name: curUser.name, userId: curUser.id };
  };

  const addNewSubscriber = (newSub) => {
    let indexOfSub = subscriber.findIndex(
      (sub) => sub.userId === newSub.userId
    );
    if (indexOfSub === -1) {
      setSubscriber([...subscriber, { ...newSub }]);
    } else {
      let subs = subscriber;
      subs[indexOfSub].streamManager = newSub.streamManager;
      setSubscriber(subs);
    }
  };
  async function createMediaPipeLine() {
    let caseid = history.location.state.sessionId
    if (recordingOn) return
    try {
      let response = await api.get('/case/create-media-pipeline/' + caseid)
      if (response && response.status && response.status == 200) {
        if (chimeRef.current) {
          chimeRef.current.sendRecordingEvent(true)
        }
        setRecordingOn(true)
      }
      else {
        cogoToast.error('Something went wrong while starting recording')
      }
    }
    catch (err) {
      console.log('pipline start error', err)
    }
  }
  async function stopMediaPipeLine() {
    let caseid = history.location.state.sessionId
    if (!recordingOn) return
    try {
      let response = await api.get('/case/concat-media-pipeline/' + caseid)
      if (response && response.status === 200) {
        if (chimeRef.current) {
          chimeRef.current.sendRecordingEvent(false)
        }
        setRecordingOn(false)
      }
      else {
        cogoToast.error('Something went wrong while stopping recording')
      }
    }
    catch (err) {
      console.log('pipline error', err)
    }

  }
  function handleCustomerLeave() {
    if (!multiUser) {
      setCustomerJoind(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div class="wrapper light-blue-template">
        <div className="header">Agent</div>
        <section className="overflow-auto">
          <div class="container-fluid mt-4 mb-5">
            <div class="row" style={{ minHeight: "76vh" }}>
              {!multiUser && history.location.state && (
                <div class="col-md-4">
                  <ClientInfo
                    sessionId={history.location.state.sessionId}
                    caseName={history.location.state.caseName}
                    userLocation={userLocation}
                    connectionId={connectionId}
                    userId={history.location.state.userId}
                    customerJoind={customerJoind}
                    processDoc={processDoc}
                    processId={processId}
                    takeScreenShot={() => takeScreenShot(subscriber[0])}
                    caseDetails={caseDetails}
                    setLastStep={setLastStep}
                  />
                  <canvas
                    id={"userImg-test"}
                    width="180"
                    height="180"
                    style={{ visibility: "hidden", position: "fixed" }}
                  />
                </div>
              )}
              {multiUser && history.location.state && (
                <div class="col-md-4">
                  <MultiUserClientInfo
                    sessionId={history.location.state.caseId}
                    subscriber={subscriber}
                    caseName={history.location.state.caseName}
                    userLocation={userLocation}
                    caseId={history.location.state.caseId}
                    customerJoind={customerJoind}
                    connectionId={connectionId}
                    processDoc={processDoc}
                    processId={processId}
                    userId={history.location.state.userId}
                    allConnections={allConnections}
                    refreshMultiDocList={refreshMultiDocList}
                    takeScreenShot={(e) => takeScreenShot(e)}
                    setLastStep={setLastStep}
                  // screenShot={screenShot}
                  />
                  <canvas
                    id={"userImg-test"}
                    width="180"
                    height="180"
                    style={{ visibility: "hidden", position: "fixed" }}
                  />
                </div>
              )}
              <div class="col-md-8">
                <div class="inner h-100 inner-2">
                  {isChimeMeetingStart && (
                    <ChimeVideoRoomComponent
                      ref={chimeRef}
                      leaveSession={(data) => leaveSession(data)}
                      handleCustomerLeave={handleCustomerLeave}
                      setCustomerJoind={async () => {
                        setCustomerJoind(true);
                        createMediaPipeLine()
                        const subscribers = await Promise.all(
                          tiles.map(async (tileId) => {
                            const { name, userId } = await getChimeSubscriberName(tileId);
                            return {
                              tileId: tileId,
                              nickname: name,
                              userId: userId
                            };
                          })
                        );

                        setSubscriber(subscribers);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {isFeedback && (
            <div data-toggle="modal" data-target="#exampleModal">
              <Feedback
                isFeedback={isFeedback}
                loading={loading}
                customerJoind={customerJoind}
                isLastStep={true}
                closeModal={() => {
                  setFeedback(false);
                }}
                id={"#exampleModal"}
                callback={(comment, isCaseComplete) => {
                  submitComment(comment, isCaseComplete);
                }}
              />
            </div>
          )}
        </section>
      </div>
    </React.Fragment>
  );
}

export default VideoCallDashboard;
