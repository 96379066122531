import React, { Component } from "react";
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./login/Login";
import { connect } from "react-redux";
import AgentDashboard from "./agent/screens/agentDashboard/AgentDashboard";
import AdminDashboard from "./admin/screens/adminDashboard/AdminDashboard";
import VideoCallDashboard from "./agent/screens/videoCallDashboard";
import ResetPassword from "./login/resetPassword/ResetPassword";
import DocumentsUpload from "./user/screens/uploadDocs/DocumentsUpload";
import VerifyPan from "./user/screens/verifyPan/VerifyPan";
import UploadDocs from "./user/screens/uploadDocs/UploadDocs";
import Customer from "./user/screens/customer/CustomerLanding.js"
import AadhaarVerificationMain from "./user/screens/aadhaarVerificationMain/AadhaarVerificationMain";
import KycInstruction from "./user/screens/kycInstruction";
import ScheduleKyc from "./user/screens/scheduleKyc/ScheduleKyc";
import CustomerConsent from "./user/screens/customerConsent/CustomerConsent";
import UserReschedule from "./user/screens/userReschedule/UserReschedule";
import RescheduleTime from "./user/screens/userReschedule/RescheduleTime";
import RescheduleAgent from "./user/screens/userReschedule/RescheduleAgent";
import ThankYou from "./user/screens/userReschedule/ThankYou";
import ChangePassword from "./login/changePassword/ChangePassword";
import VoterId from "./user/screens/voterId/VoterId";
import DrivingLicense from "./user/screens/drivingLicense/DrivingLicense";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import CaseReport from "./components/caseReport/CaseReport";
import UserSuccess from "./user/screens/userSuccess/UserSuccess";
import VerifyAadhaarOCR from "./user/screens/verifyAadhaar/VerifyAadhaarOCR";
import VerifyDLOCR from "./user/screens/verifyDrivingLicense/VerifyDLOCR";
import PDReport from "./components/pdReport/PDReport";
import PrivacyPolicyFooter from './components/privacyPolicyFooter/PrivacyPolicyFooter'
import LiveWithSchedule from "./user/screens/customer/LiveWithSchedule";
import CustomerLanding from "./PIVC/Customer/CustomerLanding";
import PivcReport from "./PIVC/Report";
import NewPrivacyPolicy from "./components/privacyPolicy/NewPrivacyPolicy.js";
class App extends Component {
  render() {
    const { userInfo = {}, anonymousUserConnectionInfo = {} } = this.props;

    return (
      <div className="App">
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/login/reset" component={ResetPassword} />
          <Route exact path="/report/:id" component={CaseReport} />
          <Route exact path="/pivc/report/:id" component={PivcReport} />

          <Route exact path="/pdreport/:id" component={PDReport} />
          <PrivateRoute
            exact
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/user/docsupload"
            component={DocumentsUpload}
          />
          <PrivateRoute
            exact
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/user/verify/pan"
            component={VerifyPan}
          />
          <PrivateRoute
            exact
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/user/verify/aadhaar"
            component={VerifyPan}
          />
          <Route
            exact
            path="/user/verify/aadhaar_ocr"
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            component={VerifyAadhaarOCR}
          />
          <Route
            exact
            path="/user/verify/dl_ocr"
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            component={VerifyDLOCR}
          />
          <PrivateRoute
            exact
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/user/verify/voterid"
            component={VoterId}
          />
          <PrivateRoute
            exact
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/user/verify/drivinglicense"
            component={DrivingLicense}
          />
          <PrivateRoute
            userInfo={userInfo}
            path="/video/dashboard"
            component={VideoCallDashboard}
          />
          <Route exact path="/privacy" component={NewPrivacyPolicy} />

          <Route exact path="/user" component={Customer} />
          <Route exact path="/user/pivc/:userToken" component={CustomerLanding} />
          <Route exact path="/user/:userToken" component={Customer} />
          <Route exact path="/user/live/:userToken" component={Customer} />
          <Route exact path="/user/live-with-schedule/:userToken" component={LiveWithSchedule} />
          <Route exact path="/reschedule" component={UserReschedule} />
          <Route path="/reschedule/time" component={RescheduleTime} />
          <Route path="/reschedule/agent" component={RescheduleAgent} />
          <Route path="/thankyou" component={ThankYou} />
          <Route exact path="/user-success/:user" component={UserSuccess} />
          <Route exact path="/customerConsent" component={CustomerConsent} />

          <PrivateRoute
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            exact
            path="/instruction"
            component={KycInstruction}
          />
          <PrivateRoute
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            exact
            path="/schedule"
            component={ScheduleKyc}
          />
          <PrivateRoute
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/dashboard"
            component={AgentDashboard}
          />
          {userInfo.isClientSuperuser && (
            <PrivateRoute
              userInfo={userInfo}
              anonymousUserConnectionInfo={anonymousUserConnectionInfo}
              path="/admin/dashboard"
              component={AdminDashboard}
            />
          )}
          <PrivateRoute
            userInfo={userInfo}
            anonymousUserConnectionInfo={anonymousUserConnectionInfo}
            path="/changePassword"
            component={ChangePassword}
          />

          <Redirect to="/login" />

        </Switch>
        <PrivacyPolicyFooter />
      </div>
    );
  }
}

const PrivateRoute = ({
  component: Component,
  userInfo,
  anonymousUserConnectionInfo,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return userInfo.accessToken ||
          (anonymousUserConnectionInfo &&
            anonymousUserConnectionInfo.connection) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.login.userInfo,
  anonymousUserConnectionInfo: state.userDocs.anonymousUserConnectionInfo,
});

export default connect(mapStateToProps, null)(App);
